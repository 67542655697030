import React, {useEffect} from 'react';
import { useHash, useCookie } from 'react-use';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import AmplifySignCom from './AmplifySignCom';
import './App.css';
function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }
const Login = () => {
    const [idToken, updateIdToken, deleteIdToken] = useCookie("id_token"); 
    const [partform, updatePartform, deletePartform] = useCookie("partform"); 
    useEffect(() => {
        updatePartform(getQueryString('partform'));
        var token=null;
        var cpartform=getQueryString('partform');
        token=getQueryString('token');

        if(token !== null) {
            signOut();
            window.location.href=cpartform=='stage'? "https://stage.creis.jp/":"https://creis.jp/";
        }
        
        const unsubscribe = onAuthUIStateChange((state, authData) => {
            if(state === AuthState.SignedIn) {
                const token = authData.signInUserSession.idToken.jwtToken;
                if(idToken === null || idToken !== token) {
                    updateIdToken(token);
                }
               
                setTimeout(()=>{
                   window.location.href = (cpartform=='stage' ? "https://cbre2.creis.jp/#/#id_token="+token:"https://cbre.creis.jp/#/#id_token="+token)+'&cur_lan='+localStorage.getItem("cur_lan");
                }, 500);
            }
        })
        
        return () => {
            unsubscribe();
        };
    }, []);
  
    useEffect(() => {
        console.log('first ');
      });
   
    
    async function signOut() {
        try {
            deleteIdToken();
            await Auth.signOut();
        } catch (error) {
            //console.log('error signing out: ', error);
        }
    }
    

    return (<>
        
        <AmplifyAuthenticator>
            
            <AmplifySignCom />
            
        </AmplifyAuthenticator>
        
        </>
    );
};

export default Login;
