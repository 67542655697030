const lan = {
    en:{
        'Sign In Account' : 'Sign In Account',
        'Username' : 'Email address',
        'Enter your username' : 'yourname@example.com',
        'Password' : 'Password',
        'Enter your password' : 'Enter your password',
        'Forgot your password?' : 'Forgot your password?',
        'Reset password' : 'Reset password',
        'No account?' : ' ',
        'Create account' : 'Create account',//'登録',
        'Sign In' : 'Sign In',
        'Sign in to your account': 'CREIS Japan ',
        'Username *': 'Email address *',
        'Password *': 'Password *',
        'Reset your password': 'Reset your password',
        'Back to Sign In': 'Back to Sign In',
        'Username cannot be empty': 'Username cannot be empty',
        'Change': 'Change',
        'Change Password': 'Change Password',
        'Verification code': 'Verification code',
        'Enter code': 'Enter code',
        'Confirmation Code': 'Confirmation Code',
        'Enter your code': 'Enter your code',
        'Confirm Sign up': 'Confirm Sign up',
        'Lost your code?': 'Lost your code?',
        'Resend Code': 'Resend Code',
        'Confirm': 'Confirm',
        'Confirm SMS Code': 'Confirm SMS Code',
        'Confirm TOTP Code': 'Confirm TOTP Code',
        'Email Address *': 'Email Address *',
        'Enter your email address': 'Enter your email address',
        'New password': 'New password',
        'Enter your new password': 'Enter your new password',
        'Phone Number *': 'Phone Number *',
        'qrcode': 'qrcode',
        '(555) 555-1212': '000-0000-0000',
        'Verify': 'Verify',
        'Send Code': 'Send Code',
        'Submit': 'Submit',
        'Sign in': 'Sign in',
        'Sign Out': 'Sign Out',
        'Email': 'Email',
        'Have an account?': ' Signuped with email, but have not completed contract yet.',
        'Create a new account': 'Create a new account',
        'PreSignUp failed with error : We don\'t accept free email as an account.':'Please input firm email address.',
        'Scan then enter verification code': 'Scan then enter verification code',
        'Enter Security Code:': 'Enter Security Code:',
        'Verify Security Token': 'Verify Security Token',
        'Account recovery requires verified contact information': 'Account recovery requires verified contact information',
        'Phone Number': 'Phone Number',
        'Sign Up Failed': 'Sign Up Failed',
        'Confirm Sign Up Failed': 'Confirm Sign Up Failed',
        'Authentication Error': 'Authentication Error',
        'The username should either be a string or one of the sign in types': 'The username should either be a string or one of the sign in types',
        'Password cannot be empty': 'Password cannot be empty',
        'Confirmation code cannot be empty': 'Confirmation code cannot be empty',
        'Error creating account': 'Error creating account',
        'Challenge response cannot be empty': 'Challenge response cannot be empty',
        'Failed to get the session because the user is empty': 'Failed to get the session because the user is empty',
        'Incorrect username or password.': 'Incorrect username or password.',
        'Create Account': 'Create Account',
        '1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must have length greater than or equal to 6': '1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must have length greater than or equal to 6',
        'Invalid verification code provided, please try again.': 'Invalid verification code provided, please try again.',
        'Attempt limit exceeded, please try after some time.': 'Attempt limit exceeded, please try after some time.',
        'Password did not conform with policy: Password not long enough': 'Password did not conform with policy: Password not long enough',
        'Attributes did not conform to the schema: email: The attribute is required': 'Attributes did not conform to the schema: email: The attribute is required',
        'User is disabled.': 'User is disabled.',
        'Attribute value for email must not be null': 'Attribute value for email must not be null',
        'Username should be either an email or a phone number.': 'Username should be either an email or a phone number.',
        'An account with the given email already exists.': 'An account with the given email already exists.',
    },
    ja:{
        'Sign In Account' : 'アカウントにサインイン',
        'Username' : 'メールアドレス',
        'Enter your username' : 'yourname@example.com',
        'Password' : 'パスワード',
        'Enter your password' : 'パスワードを入力してください',
        'Forgot your password?' : 'パスワードをお忘れの方',
        'Reset password' : 'パスワードのリセット',
        'No account?' : ' ',
        'Create account' : 'まだアカウントがない場合',//'登録',
        'Sign In' : 'ログイン',
        'Sign in to your account': 'CREIS Japan にログインします',
        'Username *': 'メールアドレス',
        'Password *': 'パスワード',
        'Reset your password': 'パスワードリセット',
        'Back to Sign In': '戻る',
        'Username cannot be empty': 'メールアドレスを空にすることはできません',
        'Change': '変更する',
        'Change Password': 'パスワードを変更する',
        'Verification code': "確認コード",
        'Enter code': '確認コード入力',
        'Confirmation Code': '確認コード',
        'Enter your code': 'コードを入力してください',
        'Confirm Sign up': 'メールアドレスの確認',
        'Lost your code?': 'コードを紛失しましたか？',
        'Resend Code': 'コードを再送する',
        'Confirm': '確認',
        'Confirm SMS Code': 'SMSコードを確認する',
        'Confirm TOTP Code': 'TOTPコードを確認する',
        'Email Address *': 'メールアドレス *',
        'Enter your email address': 'メールアドレスを入力してください',
        'New password': '新しいパスワード',
        'Enter your new password': ' ',//'新しいパスワードを入力してください',
        'Phone Number *': '電話番号 *',
        'qrcode': 'QRコード',
        '(555) 555-1212': '000-0000-0000',
        'Verify': '確認',
        'Send Code': '確認コード送信',
        'Submit': '確認',
        'Sign in': 'こちら',
        'Sign Out': 'サインアウト',
        'Email': 'メール',
        'Have an account?': 'メールアドレスは登録済みで、まだ契約手続きが完了していない方は',
        'Create a new account': 'メールアドレスを登録する',
        'PreSignUp failed with error : We don\'t accept free email as an account.':'会社メールでご登録頂きますようお願い致します。',
        'Scan then enter verification code': 'スキャンして確認コードを入力します',
        'Enter Security Code:': 'セキュリティコードを入力してください：',
        'Verify Security Token': 'セキュリティトークンを確認する',
        'Account recovery requires verified contact information': 'アカウントの復旧には、確認済みの連絡先情報が必要です',
        'Phone Number': '電話番号',
        'Sign Up Failed': 'サインアップに失敗しました',
        'Confirm Sign Up Failed': 'サインアップに失敗したことを確認する',
        'Authentication Error': '認証エラー',
        'The username should either be a string or one of the sign in types': 'メールアドレスは、文字列またはサインインタイプのいずれかである必要があります',
        'Password cannot be empty': 'パスワードを空にすることはできません',
        'Confirmation code cannot be empty': '確認コードを空にすることはできません',
        'Error creating account': 'アカウントの作成中にエラーが発生しました',
        'Challenge response cannot be empty': 'チャレンジレスポンスを空にすることはできません',
        'Failed to get the session because the user is empty': 'メールアドレスが空であるため、セッションを取得できませんでした',
        'Incorrect username or password.': 'メールアドレスまたはパスワードが違います。',
        'Create Account': '登録',
        '1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must have length greater than or equal to 6': '1つの検証エラーが検出されました：「パスワード」の値が制約を満たせませんでした：メンバーの長さが6以上である必要があります',
        'Invalid verification code provided, please try again.': '無効な確認コードが提供されました。再試行してください。',
        'Attempt limit exceeded, please try after some time.': '試行制限を超えました。しばらくしてから試してください。',
        'Password did not conform with policy: Password not long enough': 'パスワードがポリシーに準拠していません：パスワードの長さが足りません',
        'Attributes did not conform to the schema: email: The attribute is required': '属性がスキーマに準拠していませんでした：email：属性が必要です',
        'User is disabled.': 'メールアドレスが無効になっています。',
        'Attribute value for email must not be null': 'メールを入力してください',
        'Username should be either an email or a phone number.': 'メールアドレスは、電子メールまたは電話番号のいずれかである必要があります。',
        'An account with the given email already exists.': '指定されたメールアドレスのアカウントは既に存在します。',
    }
};

export default lan;