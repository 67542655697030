import React, { useState, useEffect, useCallback } from 'react';
import { useHash, useCookie } from 'react-use';
import { Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { get, post } from './util.js'
import {jwtDecode} from './js-decode.js'
import city from './city'
import './assets/css/bootstrap.min.css'
import './assets/css/app.min.css'
import Cookies from 'js-cookie'

import { googleSignInButton } from '@aws-amplify/ui';

function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}
function App() {
    
    const [area, setArea] = useState('8010100')
    const [param2, setParam2] = useState([{ value: '空室率', checked: true }, { value: '新規供給', checked: false },
     { value: '新規需要', checked: false }, { value: '貸室総面積', checked: false }, { value: '空室面積', checked: false },
     { value: '想定成約賃料', checked: false }])
    const [param3, setParam3] = useState([{ value: '主な新築物件', checked: false },{ value: '供給予定の物件', checked: false }])
    const [content, setContent] = useState('office')
    const [dateType, setDateType] = useState('Quarter')
    const [second, setSecond] = useState([])
    const [secondCode, setSecondCode] = useState([])
    const [third, setThird] = useState([])
    const [grade, setGrade] = useState("")
    const [gradeList, setGradeList] = useState(['オールグレード','Grade A','Grade Aマイナス','Grade B','サブグレード'])
    const [fscode, setFscode] = useState("")
    const [ftcode, setFtcode] = useState("")
    const [thirdCode, setThirdCode] = useState([])
    const [isAdmin, setIsAdmin] = useState(false)

    const [idToken, updateIdToken, deleteIdToken] = useCookie("id_token");
    

    useEffect(() => {
        let id_token=getQueryString('id_token')!="" ? getQueryString('id_token'):Cookies.get('id_token') 
        if(id_token==null || id_token==""){
            window.location.href="/login";
        }
        console.log(id_token)
        let email=getQueryString('email')
        var decoded = jwtDecode(id_token);
        var domainSub=domainSub = "."+document.domain;
        let name = decoded['email'].split('@')[0]
        console.log(decoded['email'])
        console.log(email)
        if (decoded && decoded['email']==email) {
            Cookies.set('name', name,{ domain: domainSub });
            Cookies.set('email_verified', decoded['email_verified'],{ domain: domainSub });
            Cookies.set('email', decoded['email'],{ domain: domainSub });
           //save  login email;
            if (decoded['cognito:groups']) {
            Cookies.set('groups', decoded['cognito:groups'].join(' '),{ domain: domainSub });
            }else{
                Cookies.set('groups', 'user',{ domain: domainSub });	
            }

        }else{
            window.location.href="/login";
        }
        let userGroups = Cookies.get('groups') || '';
        setIsAdmin(userGroups.indexOf('admin') > -1 ? true : false);
        setFirstArea(area);

    }, [area]);
   
    const setSecondArea = useCallback((code) => {
        scode=secondCode
        if(code=="all"){
            var sscode=[];
            var tcode=[];
            for(var d in second){
                sscode.push(second[d].code);
                if(second[d].lv3 !== undefined && second[d].lv3.length>0 ){
                    for(var a in second[d].lv3){
                        tcode.push(second[d].lv3[a].code);
                    }  
                }
            }
            setThird([]);
            setSecondCode(sscode);
            setThirdCode(tcode);
            
        }else if(code!="" || code.length>0){

            var scode=[code]
            setSecondCode(scode);
            var obj3=[]
            for(var d in second){
                if(in_array(second[d].code,scode) && second[d].lv3 !== undefined && second[d].lv3.length>0 ){
                    
                    obj3=obj3.concat(second[d].lv3);
                }
            
            } 
            setThird(obj3);
            setThirdCode([]);
        }
        if(code!="" || code.length>0){
            setFscode(code)
        }
    }, [second,secondCode]);
    var tcode=[];
    const setThirdArea = useCallback((code) => {
       
        tcode=thirdCode
        if(code=="all"){
                tcode=[];
                for(var d in third){
                    tcode.push(third[d].code)    
                }
            
        }else if(code!=""){
            
            tcode=[code]
        }
        setThirdCode(tcode);
        setFtcode(code);
    }, [third,thirdCode]);
    useEffect(() => {
        if (second.length > 0) {
            setSecondArea('');
        } else {
            setThird([]);
            setThirdCode([]);
        }
    }, [second, setSecondArea]);
    
    function changeCheckbox(i) {
        let tem = [...param2];
        tem[i].checked = !tem[i].checked
        setParam2(tem);
    }
    function in_array(search,array){
        for(var i in array){
            if(array[i]==search){
                return true;
            }
        }
        return false;
    }
    function getCheckParam2() {
        let str = ""
        param2.map(e => {
            if (e.checked) str += ' ' + e.value;
        })
        return str;
    }
    function changeCheckbox3(i) {
        let tem = [...param3];
        tem[i].checked = !tem[i].checked
        setParam3(tem);
    }
    function getCheckParam3() {
        let str = ""
        param3.map(e => {
            if (e.checked) str += ' ' + e.value;
        })
        return str;
    }

    function getgrade(){
        var newgrade=""
        if(grade==""){
            newgrade=gradeList.join(",")
        }else{
            newgrade=grade
        }
        return newgrade
    }
    function goto(type){
       
        if(getCheckParam2()!="" || getCheckParam3()!=""){
            var tcode=thirdCode
            var scode=secondCode
            scode=scode.concat(tcode);
            let link='https://api.cbredata.com/api/v1/research/'+(type==3?'downloadxls':'download')+'?area=' +  (scode ? scode.join(',') : area) + '&grade=' + getgrade()+ '&dataType=' + getCheckParam2()+" "+getCheckParam3() + '&sector=' + content+ '&dateType=' + dateType+'&codeType='+type+'&token='+idToken;
            document.location.href=link;
        }else{
            alert("Please select One or more data.")
            return false;
        }
    }
    function apply_goto(){
        let email=getQueryString('email')
        
        if(getCheckParam2()!="" || getCheckParam3()!=""){
            var tcode=thirdCode
            var scode=secondCode
            scode=scode.concat(tcode);
            let link='https://api.cbredata.com/api/v1/research/adv_request?filter=AREA: ' + (scode ? scode.join(',')  : area) + '; DataType: ' + getCheckParam2()+" "+getCheckParam3() + '; Sector: ' + content+ '; DateType: ' + dateType+'&email='+email+'&token='+idToken;
            document.location.href=link;
        }else{
            alert("Please select One or more data.")
            return false;
        }
    }
    function setFirstArea(code){
        let firstCode = code;
        setArea(firstCode);
        let firObj = city.filter(eee=>eee.code===firstCode && eee.lv2 !== undefined);
        if (firObj.length !== 0) {
            setSecond(firObj[0].lv2);
            var ssecond=firObj[0].lv2;
            var sscode=[];
            var tcode=[];
            for(var d in ssecond){
                sscode.push(ssecond[d].code);
                if(ssecond[d].lv3 !== undefined && ssecond[d].lv3.length>0 ){
                    for(var a in ssecond[d].lv3){
                        tcode.push(ssecond[d].lv3[a].code);
                    }  
                }
            }
            setThird([]);
            setFscode("");
            setFtcode("");
            setSecondCode(sscode);
            setThirdCode(tcode);
        } else {
            setSecond([]);
            setSecondCode([]);
        }
    }

    return (

        <section>

            <div class="row" >
                <div class="col-xl-12">
                    <div class="card-box pb-2">
                        <h4 class="header-title mb-3">download</h4>
                        <form role="form" class="parsley-examples" >
                            <div class="form-group row">
                                <div class="form-body-container">
                                    <div class="row">
                                        <label class="col-2 col-form-label text-center">SECTOR</label>
                                        <div class="col-10 col-form-label">
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" id="inlineRadio1" value="office" name="radioInline" checked={content === 'office'} onChange={(e) => setContent(e.target.value)} />
                                                <label for="inlineRadio1"> Office </label>
                                            </div>
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" id="inlineRadio2" value="industrial" name="radioInline" disabled checked={content === 'industrial'} onChange={(e) => setContent(e.target.value)} />
                                                <label for="inlineRadio1"> Industrial </label>
                                            </div>
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" id="inlineRadio3" value="retail" name="radioInline" disabled checked={content === 'retail'} onChange={(e) => setContent(e.target.value)} />
                                                <label for="inlineRadio1"> Retail </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-2 col-form-label text-center">AREA</label>
                                        <div class="col-3 col-form-label">
                                            <select class="form-control" value={area} onChange={(e) => setFirstArea(e.target.value)}>

                                                {city.map((ee)=>{
                                                        return ( <option value={ee.code}>{ee.name_jp}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div class="col-3 col-form-label">
                                            {
                                                second.length>0 && 
                                                (
                                                    <select class="form-control" value={fscode}  onChange={(e) => setSecondArea(e.target.value)}>
                                                        <option value="all">All</option>
                                                        {second.map((ee)=>{
                                                                    return ( <option value={ee.code}>{ee.name_jp}</option>)
                                                            })}
                                                    </select>
                                                )
                                            }
                                        </div>
                                        <div class="col-3 col-form-label">
                                        {
                                                third.length>0 && 
                                                (
                                                    <select class="form-control" value={ftcode} onChange={(e) => setThirdArea(e.target.value)}>
                                                        <option value="all">All</option>
                                                        {third.map((ee)=>{
                                                                    return ( <option value={ee.code}>{ee.name_jp}</option>)
                                                            })}
                                                    </select>
                                                )
                                            }
                                        </div>
                                    </div>
                                <div class="row">
                                    <label class="col-2 col-form-label text-center">DATA1</label>
                                    <div class="col-10 col-form-label">
                                        {param2.map((e, i) => (
                                            <div
                                                class="checkbox form-check-inline"

                                            >
                                                <input type="checkbox" checked={e.checked} onChange={() => changeCheckbox(i)} />
                                                <label for="inlineCheckbox1"> {e.value} </label>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-2 col-form-label text-center">DATA Grade</label>
                                    <div class="col-3 col-form-label">
                                        <select class="form-control" value={grade} onChange={(e) => setGrade(e.target.value)}>
                                                        <option value="">All</option>
                                                        {gradeList.map((ee)=>{
                                                                    return ( <option value={ee}>{ee}</option>)
                                                            })}
                                                    </select>

                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-2 col-form-label text-center">Date Type</label>
                                    <div class="col-10 col-form-label">
                                        <div class="radio radio-info form-check-inline">
                                            <input type="radio" id="dateType1" value="All" name="dateType" checked={dateType === 'All'} onChange={(e) => setDateType(e.target.value)} />
                                            <label for="inlineRadio1"> All </label>
                                        </div>
                                        <div class="radio radio-info form-check-inline">
                                            <input type="radio" id="dateType2" value="Quarter" name="dateType" checked={dateType === 'Quarter'} onChange={(e) => setDateType(e.target.value)} />
                                            <label for="inlineRadio1"> Quarter </label>
                                        </div>
                                        <div class="radio radio-info form-check-inline">
                                            <input type="radio" id="dateType3" value="Annual" name="dateType" checked={dateType === 'Annual'} onChange={(e) => setDateType(e.target.value)} />
                                            <label for="inlineRadio1"> Annual </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="topline"></div>
                                <div class="row">
                                    <label class="col-2 col-form-label text-center">DATA2</label>
                                    <div class="col-10 col-form-label">
                                        {param3.map((e, i) => (
                                            <div
                                                class="checkbox form-check-inline"

                                            >
                                                <input type="checkbox" checked={e.checked} onChange={() => changeCheckbox3(i)} />
                                                <label for="inlineCheckbox1"> {e.value} </label>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                </div>
                            </div>
                        </form>
                        {/* {isAdmin && ( */}
                            <div class="app-container download-button">
                                <div class="btn-group mb-2 text-center" >
                                    <a href="####" 
                                        type="button"
                                        class="btn btn-blue waves-effect waves-light margin5"
                                        onClick={() => goto(1)}
                                    >download csv utf-8</a>


                                </div>
                                <div class="btn-group mb-2 text-center" >
                                    <a href="####" 
                                        type="button"
                                        class="btn btn-blue waves-effect waves-light margin5"
                                        onClick={() => goto(2)}
                                    >download csv shift-jis</a>


                                </div>
                                <div class="btn-group mb-2 text-center" >
                                    <a href="####" 
                                        type="button"
                                        class="btn btn-blue waves-effect waves-light margin5"
                                        onClick={() => goto(3)}
                                    >download xls</a>


                                </div>
                            </div>
                        {/* )} */}

                    </div>
                </div>
            </div>
            {isAdmin && ( 
            <div class="row" >
                <div class="col-xl-12">
                    <div class="card-box pb-2">
                        <h4 class="header-title mb-3">Apply download</h4>
                        <form role="form" class="parsley-examples" >
                            <div class="form-group row">
                                <div class="form-body-container">
                                    <div class="row">
                                        <label class="col-2 col-form-label text-center">SECTOR</label>
                                        <div class="col-10 col-form-label">
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" id="inlineRadio1" value="office" name="radioInline" checked={content === 'office'} onChange={(e) => setContent(e.target.value)} />
                                                <label for="inlineRadio1"> Office </label>
                                            </div>
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" id="inlineRadio2" value="industrial" name="radioInline" checked={content === 'industrial'} onChange={(e) => setContent(e.target.value)} />
                                                <label for="inlineRadio1"> Industrial </label>
                                            </div>
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" id="inlineRadio3" value="retail" name="radioInline" checked={content === 'retail'} onChange={(e) => setContent(e.target.value)} />
                                                <label for="inlineRadio1"> Retail </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-2 col-form-label text-center">AREA</label>
                                        <div class="col-3 col-form-label">
                                            <select class="form-control" value={area} onChange={(e) => setFirstArea(e.target.value)}>
                                            
                                                {city.map((ee)=>{
                                                        return ( <option value={ee.code}>{ee.name_jp}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div class="col-3 col-form-label">
                                            {
                                                second.length>0 && 
                                                (
                                                    <select class="form-control"  value={fscode} onChange={(e) => setSecondArea(e.target.value)}>
                                                        {second.map((ee)=>{
                                                                    return ( <option value={ee.code}>{ee.name_jp}</option>)
                                                            })}
                                                    </select>
                                                )
                                            }
                                        </div>
                                        <div class="col-3 col-form-label">
                                        {
                                                third.length>0 && 
                                                (
                                                    <select class="form-control" value={ftcode} onChange={(e) => setThirdArea(e.target.value)}>
                                                        {third.map((ee)=>{
                                                                    return ( <option value={ee.code}>{ee.name_jp}</option>)
                                                            })}
                                                    </select>
                                                )
                                            }
                                        </div>
                                    </div>
                                <div class="row">
                                    <label class="col-2 col-form-label text-center">DATA1</label>
                                    <div class="col-10 col-form-label">
                                        {param2.map((e, i) => (
                                            <div
                                                class="checkbox form-check-inline"

                                            >
                                                <input type="checkbox" checked={e.checked} onChange={() => changeCheckbox(i)} />
                                                <label for="inlineCheckbox1"> {e.value} </label>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-2 col-form-label text-center">Date Type</label>
                                    <div class="col-10 col-form-label">
                                        <div class="radio radio-info form-check-inline">
                                            <input type="radio" id="dateType1" value="All" name="dateType" checked={dateType === 'All'} onChange={(e) => setDateType(e.target.value)} />
                                            <label for="inlineRadio1"> All </label>
                                        </div>
                                        <div class="radio radio-info form-check-inline">
                                            <input type="radio" id="dateType2" value="Quarter" name="dateType" checked={dateType === 'Quarter'} onChange={(e) => setDateType(e.target.value)} />
                                            <label for="inlineRadio1"> Quarter </label>
                                        </div>
                                        <div class="radio radio-info form-check-inline">
                                            <input type="radio" id="dateType3" value="Annual" name="dateType" checked={dateType === 'Annual'} onChange={(e) => setDateType(e.target.value)} />
                                            <label for="inlineRadio1"> Annual </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="topline"></div>
                                <div class="row">
                                    <label class="col-2 col-form-label text-center">DATA2</label>
                                    <div class="col-10 col-form-label">
                                        {param3.map((e, i) => (
                                            <div
                                                class="checkbox form-check-inline"

                                            >
                                                <input type="checkbox" checked={e.checked} onChange={() => changeCheckbox3(i)} />
                                                <label for="inlineCheckbox1"> {e.value} </label>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                </div>
                            </div>
                        </form>
                        {/* {isAdmin && ( */}
                            <div class="app-container download-button">
                                <div class="btn-group mb-2 text-center" >
                                    <a href="####" 
                                        type="button"
                                        class="btn btn-blue waves-effect waves-light margin5"
                                        onClick={() => apply_goto()}
                                    >Apply</a>


                                </div>
                            </div>
                        {/* )} */}

                    </div>
                </div>
            </div>
             )}
        </section>




    );
}

export default App;