import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Rout from './Rout'
import reportWebVitals from './reportWebVitals';
import Amplify, { I18n } from "aws-amplify";

import lan from "./language";

I18n.putVocabularies(lan)


function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}

let language=getQueryString('language')!="" ? getQueryString('language'):'jp';

if(language=='jp'){
  I18n.setLanguage('ja');
  localStorage.setItem("cur_lan",'jp');
}else{
  I18n.setLanguage('en');
  localStorage.setItem("cur_lan",'en');
}

Amplify.configure({
  Auth: {

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'ap-northeast-1:f9c12af2-72e7-4dc6-9104-469476f96906',

      // REQUIRED - Amazon Cognito Region
      region: 'ap-northeast-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'ap-northeast-1_PnrDJYiCd',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '7c4noa6fojsaa16l8ccqht8e7h',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      
  }
});

// You can get the current config object
//const currentConfig = Auth.configure();


ReactDOM.render(
  <React.StrictMode>
    <Rout />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
