import React, {useEffect,useLayoutEffect,useRef,useState} from 'react';
import { useHash, useCookie } from 'react-use';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AmplifyForgotPassword, AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react';
//import AmplifySignCom from './AmplifySignCom';
import './App.css';
import s from './App.css';

const Login = () => {
    const [hash, setHash] = useHash();
    const [refresh, setRefresh] = useState(false);
    const [idToken, updateIdToken, deleteIdToken] = useCookie("id_token");
    const [curlan, setCurlan] = useState(0);

    useEffect(() => {
        const unsubscribe = onAuthUIStateChange((state, authData) => {
            if(state === AuthState.SignedIn) {
                const token = authData.signInUserSession.idToken.jwtToken;
                if(idToken === null || idToken !== token) {
                    updateIdToken(token);
                }
                setTimeout(()=>{
                    window.location.href = "https://creis.jp/#/#id_token="+token;
                }, 500);
            }
        })
        
        return () => {
            unsubscribe();
        };
    }, []);

   
    useEffect(() => {
       
        if (hash) {
            const query = hash.replace('#','');
            const tem = query.split('&');
            const token = tem.map((ee) => {
                if (ee) {
                    let a = ee.split('=');
                    return a[1];
                };
                return null;
            })

            if(token !== null) {
                signOut();
            }
        } 
    })
    useLayoutEffect(() => {  
        setTimeout(function(){
            
            document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section").shadowRoot.querySelector("form > amplify-section > div:nth-child(3) > slot > div > amplify-button:nth-child(1)").shadowRoot.querySelector("button").onclick=function(){
                    var refConceal3;
                    setTimeout(function(){
                        let show=0;
                        if(document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section > amplify-auth-fields").shadowRoot.querySelector("div > amplify-password-field")!=undefined && document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section > amplify-auth-fields").shadowRoot.querySelector("div > amplify-password-field").shadowRoot.querySelector("amplify-form-field").shadowRoot.querySelector("div > div:nth-child(2) > slot > amplify-input")!=undefined  && document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section > amplify-auth-fields").shadowRoot.querySelector("div > amplify-password-field").shadowRoot.querySelector("amplify-form-field").shadowRoot.querySelector("#conceal3")==undefined){
                            document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section > amplify-auth-fields").shadowRoot.querySelector("div > amplify-password-field").shadowRoot.querySelector("amplify-form-field").shadowRoot.querySelector("div > div:nth-child(2) > slot > amplify-input").style.position='relative';
                            document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section > amplify-auth-fields").shadowRoot.querySelector("div > amplify-password-field").shadowRoot.querySelector("amplify-form-field").shadowRoot.querySelector("div > div:nth-child(2) > slot > amplify-input").style.display='inline-block';
                            document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section").shadowRoot.querySelector("form > amplify-section > div:nth-child(3) > slot > div > amplify-button:nth-child(2)").style.display="none";
                            document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section > amplify-auth-fields").shadowRoot.querySelector("div > amplify-password-field").shadowRoot.querySelector("amplify-form-field").shadowRoot.querySelector("div > div:nth-child(2) > slot > amplify-input").insertAdjacentHTML('beforeend', '<div class="conceal " style="position: absolute;top: 12px;z-index: 2;right: 20px;width: 30px;height: 30px;background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAkxJREFUaEPt2E+oT1EQB/DPW9lQVpTyZ4UoEimxkIUNlsiCsmBrI6UUFqRs7MTGv3qFlBVlQ9goLCis/IkiK8RO0dS9r9vt/n3HS7fu1On8XufMnPnOd87cOW/CwGVi4P4bAfxvBkcGRgYSIzCmUGIAk9VHBpJDmGhgZCAL4GKsQ8xLsjl+x/iDd4XxCR/xDB8SCUhuJbZiF3Zjdk9nfuI6buBeT92p7dNJoXkFpzdN9+CS3uMCmK99bPYFsAXnsbTPIT32vsUR3Oqq0wfAPlxpMXwbDxF5nud6zItKYxua2DuFY11AdAVwFodrDN5FjGv41uXQbM8K7MzGygq9OwigjdIFwBssq7DyACcRc1n2Ygc24DceIfL8Yo0353CoYu0zFjQhaAPwGssrDITjJ2oMR1WJyFZJlM4ot1WyB5cwq7T4EqvqQDQBOI2jJcX32F8T9dj6FGtbWL+ZVbG6bcHUxtJi+HGmSqEOwHo8KSn8wvYG5w/iQlvOZutREOLO1MkXzC8trsaLskIdgB+YU9ockb/ccOhVRO7nkt+PqOvxsTteWGtjYQ2el876jrldAEwi8rEoUZujEjVJtAvRRuQSleVV9sdm3C+sRWld2GLvQMWlD9aCvSmpYmDwAALdv06hqErFqjWjKRQABn+JA8Sgy2h+SQb9IctBDLqVyEEMupnLQQy6nc5BDPpBk4MY9JOy/PUf5KO+qoUZ7L9VWvqxmV9ue5HNvAeJJ4wAEgOYrD4ykBzCRAMjA4kBTFYfGUgOYaKBkYHEACar/wVzOJ4xdD9a1gAAAABJRU5ErkJggg==);background-size: 100% 100%;cursor: pointer;" tabindex="0" id="conceal3"></div>');
                            
                            refConceal3=document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section > amplify-auth-fields").shadowRoot.querySelector("div > amplify-password-field").shadowRoot.querySelector("amplify-form-field").shadowRoot.querySelector("#conceal3");
                            console.log(refConceal3);
                            refConceal3.onmousedown=function(){//document对象代表当前该HTML文档
                        
                                //lightSwitch()
                                if(show==1){
                                    show=0;
                                }else{
                                    show=1;
                                }
                                refConceal3.style.backgroundImage='url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABJBJREFUaEPtV91rHFUU/51JTKrVYNOZ2dkmymZn1bSKD7HiR0Da+qIo+FHiZ+ujWiEiiC8WNX5AKfqkFUUfWm3R0ogff4Daom1RKRYVsXFnm8a6O7MzKWrTxtrsHLmT3XZ3s7PZ2ckikbkwMMw953d/5/zOPfcOYZEPWuT8EQXwXysYKRApEDIDUQmFTGBo90iB0CkMCRApEDKBod3/XwroMWUYhHsBvGeY9o7Q6VlAgGRMfoqI7mLwvozpjJSgKxTQNSUPQPEmGU8alv3GAnJoGioVVzcw885ZWnwiYzrLfQJQXwP46fOTvDZtOnubXnkBHPVY7BqQ+2MJiglbMjn72ZoBiI+6pn4O8LqiwbRh2hctAI+mIXRN+RNAl5d95l0Zy9lYDjZnE/f2dnV3znSmASwrGo4Zpn1V0wxCOOqacgjAQLGkf+900f+zbU/VDUBM9sWVWyTGvjLDKdelwaP5/A8h+DTsmtTkOwj0EYAlJac2yV05lp38pRrEt40mNfUJAr9ZES1jc9qytwIoNMwmgOEaoH0iJu8goofL3Zj5oYzlfFgLqu45kNLkEQa9UOV4kNjdmrYmPwvAbV5TPa7eA+YtACrKlYnWZ3L5j/0A6gaga8oeAEM+zt8BNFqANDpumuPzMqxhkNC0RBvcIYDFGtfPNeFj01LHqmw2ezpwAKme7l4utP3m7X7gqES0iZlfAbC6ahOdYmBUPFTAmGHbogH4jitisWSB3H4ChsTDwNIq4yMMdBDQ530nftTIOe8GDkCPK5vBEIQFysuGmX8+1d3dxR3tjwN8H4DrfEBPAzQO8DEAaRBcMPUBnGSgj+YSLsF8RcSfStTxfsE9eyeA7cW1vzDM/K3BA9DknwC6WjgW2L1x3Jr8phxEjyu3AfQImB8QETZTQgBcBt6ChN2ZrP11CSORSCxp//uUU1KHJAyks/b3DW/ilKrexBIf8HIP+jZt5m/wI6hrimhtsxuPsR/ElwJ0OYBLqnxOAjwBkARgZXFu2DDtbbWwda3iVrDNMO3hhgNIavIIFbsPg18svzyVg6xSlIvPtEGclIIULjPtC/YCM+JdHIgXcqcIBNN0ZuL48b9OiPeUJq9h0JezOPy2YTqbagagqjdD4v3FuZOGaXuncfWoKb2uKbcDeF2sB+LVRs4RJ+KckYrLA8xUmjtkmHbFBq/l09/Ts/xs4R/HmyMcMHL2oL+65681hNr3Mt/aTWmaMgMsrdci9ZhyPwi7Z5NJ7xhW/jE/MuXfdU05AuBKAFOGaVeX2jnTlKaILrUHjD8kF2t/te3DDSnQCAlho2vycwC9VLT3redqPD2ufgDmB724Wbo2Y1nnbpvVtuKsaMdMwu9W3Gz38NZJxuSdRLShWM/rDNMp1nb9FJS3aCLamM7ldzWatAVWoOykZilmWJb4IZp36CvUu+HyJ54CdZrEvEAh+reHra9QB8F4htk97Nep/Deosh1Ey0B41cjmS92mEc4VNqFKKPBqLXCIAmhBUgNBRgoESlcLjCMFWpDUQJCRAoHS1QLjSIEWJDUQZKRAoHS1wHjRK/AvYGKJQLT2guEAAAAASUVORK5CYII=)';
                                refConceal3.style.backgroundSize='100% 100%';
                                console.log(show);
                                document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section > amplify-auth-fields").shadowRoot.querySelector("div > amplify-password-field").shadowRoot.querySelector("amplify-form-field").shadowRoot.querySelector("#password").type=show==1 ?'text':'password';
                            }
                            refConceal3.onmouseup=function(){//document对象代表当前该HTML文档
                            
                            // lightSwitch()
                            if(show==1){
                                    show=0;
                                
                                }else{
                                    show=1;
                                
                                }
                                refConceal3.style.backgroundImage='url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAkxJREFUaEPt2E+oT1EQB/DPW9lQVpTyZ4UoEimxkIUNlsiCsmBrI6UUFqRs7MTGv3qFlBVlQ9goLCis/IkiK8RO0dS9r9vt/n3HS7fu1On8XufMnPnOd87cOW/CwGVi4P4bAfxvBkcGRgYSIzCmUGIAk9VHBpJDmGhgZCAL4GKsQ8xLsjl+x/iDd4XxCR/xDB8SCUhuJbZiF3Zjdk9nfuI6buBeT92p7dNJoXkFpzdN9+CS3uMCmK99bPYFsAXnsbTPIT32vsUR3Oqq0wfAPlxpMXwbDxF5nud6zItKYxua2DuFY11AdAVwFodrDN5FjGv41uXQbM8K7MzGygq9OwigjdIFwBssq7DyACcRc1n2Ygc24DceIfL8Yo0353CoYu0zFjQhaAPwGssrDITjJ2oMR1WJyFZJlM4ot1WyB5cwq7T4EqvqQDQBOI2jJcX32F8T9dj6FGtbWL+ZVbG6bcHUxtJi+HGmSqEOwHo8KSn8wvYG5w/iQlvOZutREOLO1MkXzC8trsaLskIdgB+YU9ockb/ccOhVRO7nkt+PqOvxsTteWGtjYQ2el876jrldAEwi8rEoUZujEjVJtAvRRuQSleVV9sdm3C+sRWld2GLvQMWlD9aCvSmpYmDwAALdv06hqErFqjWjKRQABn+JA8Sgy2h+SQb9IctBDLqVyEEMupnLQQy6nc5BDPpBk4MY9JOy/PUf5KO+qoUZ7L9VWvqxmV9ue5HNvAeJJ4wAEgOYrD4ykBzCRAMjA4kBTFYfGUgOYaKBkYHEACar/wVzOJ4xdD9a1gAAAABJRU5ErkJggg==)'
                                refConceal3.style.backgroundSize='100% 100%';
                                console.log(show);
                                document.querySelector("#root > div > div > amplify-forgot-password").shadowRoot.querySelector("amplify-form-section > amplify-auth-fields").shadowRoot.querySelector("div > amplify-password-field").shadowRoot.querySelector("amplify-form-field").shadowRoot.querySelector("#password").type=show==1 ?'text':'password';
                                
                            }
                        }
                    
                    },2500)
                }
            
        },2000);
        
    }, [])

    async function signOut() {
        try {
            deleteIdToken();
            await Auth.signOut();
        } catch (error) {
            //console.log('error signing out: ', error);
        }
    }
 
    return (
        <div className={s.feeWrap}>
            <AmplifyForgotPassword slot="forgot-password" className={'forgot-password'} /> 
            <div class="hidebtn"></div>
       </div>
    );
   
        
};

export default Login;
