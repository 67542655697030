const city = [
    {
        "code": "8010100",
        "name_jp": "札幌",
        "name_en": "Sapporo",
        "lv2": [
            {
                "code": "9010101",
                "name_jp": "札幌駅周辺",
                "name_en": "Sapporo Eki"
            },
            {
                "code": "9010102",
                "name_jp": "大通公園",
                "name_en": "Oodori Park"
            },
            {
                "code": "9010100",
                "name_jp": "札幌その他",
                "name_en": "Sapporo Other"
            }
        ]
    },
    {
        "code": "8040100",
        "name_jp": "仙台",
        "name_en": "Sendai",
        "lv2": [
            {
                "code": "9040101",
                "name_jp": "仙台駅西口",
                "name_en": "Sendai Eki-Nishiguchi"
            },
            {
                "code": "9040102",
                "name_jp": "仙台駅東口",
                "name_en": "Sendai Eki-Higashiguchi"
            },
            {
                "code": "9040100",
                "name_jp": "仙台その他",
                "name_en": "Sendai Other"
            }
        ]
    },
    {
        "code": "911",
        "name_jp": "さいたま",
        "name_en": "Saitama",
        "lv2": [
            {
                "code": "9110101",
                "name_jp": "大宮東口",
                "name_en": "Omiya-Higashiguchi"
            },
            {
                "code": "9110102",
                "name_jp": "大宮西口",
                "name_en": "Omiya-Nishiguchi"
            },
            {
                "code": "9110100",
                "name_jp": "さいたまその他",
                "name_en": "Saitama Other"
            }
        ]
    },
    {
        "code": "tky23",
        "name_jp": "東京23区",
        "name_en": "Tokyo 23 Wards",
        "lv2": [
            {
                "code": "tky5",
                "name_jp": "東京主要5区",
                "name_en": "Tokyo Core Market"
            },
            {
                "code": "8130100",
                "name_jp": "丸の内・大手町",
                "name_en": "Marunouchi,Otemachi",
                "lv3": [
                    {
                        "code": "9130101",
                        "name_jp": "丸の内・大手町",
                        "name_en": "Marunouchi,Otemachi"
                    },
                    {
                        "code": "9130199",
                        "name_jp": "丸の内・大手町その他",
                        "name_en": "Marunouchi,Otemachi Other"
                    }
                ]
            },
            {
                "code": "8130200",
                "name_jp": "神田・飯田橋",
                "name_en": "Kanda,Idabashi",
                "lv3": [
                    {
                        "code": "9130201",
                        "name_jp": "番町・麹町",
                        "name_en": "Bancho,Koujimachi"
                    },
                    {
                        "code": "9130202",
                        "name_jp": "九段下・飯田橋",
                        "name_en": "Kudanshita,Iidabashi"
                    },
                    {
                        "code": "9130203",
                        "name_jp": "神田・秋葉原・御茶ノ水",
                        "name_en": "Kanda,Akihabara,Ochanomizu"
                    },
                    {
                        "code": "9130299",
                        "name_jp": "神田・飯田橋その他",
                        "name_en": "Kanda,Iidabashi Other"
                    }
                ]
            },
            {
                "code": "8130300",
                "name_jp": "八重洲・日本橋",
                "name_en": "Yaesu,Nihonbashi",
                "lv3": [
                    {
                        "code": "9130301",
                        "name_jp": "八重洲・日本橋",
                        "name_en": "Yaesu,Nihonbashi"
                    },
                    {
                        "code": "9130302",
                        "name_jp": "銀座",
                        "name_en": "Ginza"
                    },
                    {
                        "code": "9130399",
                        "name_jp": "八重洲・日本橋その他",
                        "name_en": "Yaesu,Nihonbashi Other"
                    }
                ]
            },
            {
                "code": "8130400",
                "name_jp": "六本木・赤坂",
                "name_en": "Roppongi,Akasaka",
                "lv3": [
                    {
                        "code": "9130401",
                        "name_jp": "赤坂・六本木・神谷町",
                        "name_en": "Akasaka,Roppongi,Kamiyacho"
                    },
                    {
                        "code": "9130402",
                        "name_jp": "青山・表参道",
                        "name_en": "Aoyama,Omotesando"
                    },
                    {
                        "code": "9130499",
                        "name_jp": "六本木・赤坂その他",
                        "name_en": "Roppongi,Akasaka Other"
                    }
                ]
            },
            {
                "code": "8130500",
                "name_jp": "虎ノ門・汐留",
                "name_en": "Toranomon,Shiodome",
                "lv3": [
                    {
                        "code": "9130501",
                        "name_jp": "虎ノ門・霞ヶ関",
                        "name_en": "Toranomon,Kasumigaseki"
                    },
                    {
                        "code": "9130502",
                        "name_jp": "汐留・新橋",
                        "name_en": "Shiodome,Shinbashi"
                    },
                    {
                        "code": "9139599",
                        "name_jp": "虎ノ門・汐留その他",
                        "name_en": "Toranomon,Shiodome Other"
                    }
                ]
            },
            {
                "code": "8130600",
                "name_jp": "新宿",
                "name_en": "Shinjyuku",
                "lv3": [
                    {
                        "code": "9130601",
                        "name_jp": "西新宿",
                        "name_en": "Nishishinjyuku"
                    },
                    {
                        "code": "9130699",
                        "name_jp": "新宿その他",
                        "name_en": "Shinjyuku Other"
                    }
                ]
            },
            {
                "code": "8130700",
                "name_jp": "渋谷・恵比寿",
                "name_en": "Shibuya,Ebisu",
                "lv3": [
                    {
                        "code": "9130701",
                        "name_jp": "渋谷・恵比寿",
                        "name_en": "Shibuya,Ebisu"
                    },
                    {
                        "code": "9130799",
                        "name_jp": "渋谷・恵比寿その他",
                        "name_en": "Shibuya,Ebisu Other"
                    }
                ]
            },
            {
                "code": "8130800",
                "name_jp": "品川・田町",
                "name_en": "Shinagawa,Tamachi",
                "lv3": [
                    {
                        "code": "9130801",
                        "name_jp": "品川",
                        "name_en": "Shinagawa"
                    },
                    {
                        "code": "9130802",
                        "name_jp": "浜松町・田町",
                        "name_en": "Hamamatsucho,Tamachi"
                    },
                    {
                        "code": "9130899",
                        "name_jp": "品川・田町その他",
                        "name_en": "Shinagawa,Tamachi Other"
                    }
                ]
            },
            {
                "code": "8130900",
                "name_jp": "大崎",
                "name_en": "Osaki",
                "lv3": [
                    {
                        "code": "9130901",
                        "name_jp": "大崎",
                        "name_en": "Osaki"
                    },
                    {
                        "code": "9130999",
                        "name_jp": "大崎その他",
                        "name_en": "Osaki Other"
                    }
                ]
            },
            {
                "code": "8131000",
                "name_jp": "城東",
                "name_en": "Tokyo East",
                "lv3": [
                    {
                        "code": "9131001",
                        "name_jp": "墨田・江東",
                        "name_en": "Sumida,Kouto"
                    },
                    {
                        "code": "9131002",
                        "name_jp": "晴海・有明・台場・豊洲",
                        "name_en": "Harumi,Ariake,Daiba,Toyosu"
                    },
                    {
                        "code": "9131099",
                        "name_jp": "城東その他",
                        "name_en": "Tokyo East Other"
                    }
                ]
            },
            {
                "code": "8131100",
                "name_jp": "城北",
                "name_en": "Tokyo North",
                "lv3": [
                    {
                        "code": "9131101",
                        "name_jp": "池袋",
                        "name_en": "Ikebukuro"
                    },
                    {
                        "code": "9131199",
                        "name_jp": "城北その他",
                        "name_en": "Tokyo North Other"
                    }
                ]
            },
            {
                "code": "8131200",
                "name_jp": "城西",
                "name_en": "Tokyo West",
                "lv3": [
                    {
                        "code": "9131299",
                        "name_jp": "城西その他",
                        "name_en": "Tokyo West Other"
                    }
                ]
            },
            {
                "code": "8131300",
                "name_jp": "城南",
                "name_en": "Tokyo South",
                "lv3": [
                    {
                        "code": "9131301",
                        "name_jp": "天王洲・東品川・大森・蒲田",
                        "name_en": "Tennouzu,Higashishinagawa,Omori,Kamata"
                    },
                    {
                        "code": "9131399",
                        "name_jp": "城南その他",
                        "name_en": "Tokyo South Other"
                    }
                ]
            }
        ]
    },
    {
        "code": "ykh",
        "name_jp": "横浜",
        "name_en": "Yokohama",
        "lv2": [
            {
                "code": "8140100",
                "name_jp": "横浜駅周辺",
                "name_en": "Yokohama eki"
            },
            {
                "code": "8140200",
                "name_jp": "みなとみらい",
                "name_en": "Minatomirai"
            },
            {
                "code": "9140300",
                "name_jp": "関内",
                "name_en": "Kannai"
            },
            {
                "code": "9140400",
                "name_jp": "新横浜",
                "name_en": "Shinyokohama"
            },
            {
                "code": "9141100",
                "name_jp": "川崎",
                "name_en": "Kawasaki"
            }
        ]
    },
    {
        "code": "8170100",
        "name_jp": "金沢",
        "name_en": "Kanazawa",
        "lv2": [
            {
                "code": "9170101",
                "name_jp": "金沢駅周辺",
                "name_en": "Kanazawa Eki"
            },
            {
                "code": "9170100",
                "name_jp": "金沢その他",
                "name_en": "Kanazawa Other"
            }
        ]
    },
    {
        "code": "ngy",
        "name_jp": "名古屋",
        "name_en": "Nagoya",
        "lv2": [
            {
                "code": "8230100",
                "name_jp": "名駅",
                "name_en": "Meieki"
            },
            {
                "code": "8230200",
                "name_jp": "伏見・丸の内",
                "name_en": "Fushimi,Marunouchi"
            },
            {
                "code": "8230300",
                "name_jp": "栄",
                "name_en": "Sakae"
            },
            {
                "code": "8230400",
                "name_jp": "名古屋東",
                "name_en": "Nagoya Higashi"
            },
            {
                "code": "9230099",
                "name_jp": "名古屋その他",
                "name_en": "Nagoya Other"
            },
        ]
    },
    {
        "code": "8260100",
        "name_jp": "京都",
        "name_en": "Kyoto",
        "lv2": [
            {
                "code": "9260101",
                "name_jp": "京都駅周辺",
                "name_en": "Kyoto Eki"
            },
            {
                "code": "9260102",
                "name_jp": "四条烏丸",
                "name_en": "Shijo-Karasuma"
            },
            {
                "code": "9260100",
                "name_jp": "京都その他",
                "name_en": "Kyoto Other"
            }
        ]
    },
    {
        "code": "osk",
        "name_jp": "大阪",
        "name_en": "Osaka",
        "lv2": [
            {
                "code": "8270100",
                "name_jp": "梅田",
                "name_en": "Umeda"
            },
            {
                "code": "8270200",
                "name_jp": "堂島",
                "name_en": "Dojima"
            },
            {
                "code": "8270300",
                "name_jp": "中之島",
                "name_en": "Nakanoshima"
            },
            {
                "code": "8270400",
                "name_jp": "淀屋橋",
                "name_en": "Yodoyabashi"
            },
            {
                "code": "8270500",
                "name_jp": "本町",
                "name_en": "Honmachi"
            },
            {
                "code": "8270600",
                "name_jp": "新大阪",
                "name_en": "Shinosaka"
            },
            {
                "code": "8270700",
                "name_jp": "大阪その他",
                "name_en": "Osaka Other"
            }
        ]
    },
    {
        "code": "8280100",
        "name_jp": "神戸",
        "name_en": "Kobe"
    },
    {
        "code": "8340100",
        "name_jp": "広島",
        "name_en": "Hiroshima",
        "lv2": [
            {
                "code": "9340101",
                "name_jp": "広島駅周辺",
                "name_en": "Hiroshima Eki"
            },
            {
                "code": "9340102",
                "name_jp": "広島中心部",
                "name_en": "Hiroshima Central"
            },
            {
                "code": "9340100",
                "name_jp": "広島その他",
                "name_en": "Hiroshima Other"
            }
        ]
    },
    {
        "code": "8370100",
        "name_jp": "高松",
        "name_en": "Takamatsu"
    },
    {
        "code": "8400100",
        "name_jp": "福岡",
        "name_en": "Fukuoka",
        "lv2": [
            {
                "code": "9400101",
                "name_jp": "博多駅周辺",
                "name_en": "Hakata Eki"
            },
            {
                "code": "9400102",
                "name_jp": "天神",
                "name_en": "Tenjin"
            },
            {
                "code": "9400100",
                "name_jp": "福岡その他",
                "name_en": "Fukuoka Other"
            }
        ]
    }
] 

export default city;